<template>
  <BaseModal size="small">
      <div class="block items-center justify-center">
        <img :src="url + detail.requestable.product_data_array.product_tmpl_id[0] +'.png'" alt="" class="w-full max-h-36 m-auto object-contain">
      </div>
      <div class="block w-full pb-2 text-left">
        <div class="mt-2 mb-4">
          <p class="text-vw-lightgray pb-1">{{ $t('Unieknummer') }}: {{ detail.requestable.product_data_array.lot_id[1] }}</p>
          <p class="text-vw-green pb-1">{{ detail.requestable.product_data_array.product_tmpl_id[1] }}</p>
          <!-- <p class="text-vw-lightgray pb-1">{{ detail.requestable.product_attributes.interior.name }}</p> -->
          <p class="text-vw-lightgray pb-1">
          {{ productData && productData.selectedInterior ? productData.selectedInterior.name : $t('Geen interieur gevonden') }}
        </p>
        </div>
        <div class="my-2">
          <div class="grid grid-cols-2 gap-2 pb-2">
            <div class="futura-bold">{{ $t('Inwendige lengte:') }}</div>
            <!-- <div class="text-vw-green">{{ detail.requestable.product_attributes.length.name }}</div> -->
            <div class="text-vw-green">{{ productData && productData.selectedLength ? productData.selectedLength.name : $t('N/A') }}</div>
          </div>
          <div class="grid grid-cols-2 gap-2">
            <div class="futura-bold">{{ $t('Inwendige breedte:') }}</div>
            <!-- <div class="text-vw-green">{{ detail.requestable.product_attributes.width.name }}</div> -->
            <div class="text-vw-green">{{ productData && productData.selectedWidth ? productData.selectedWidth.name : $t('N/A') }}</div>
          </div>
        </div>
      </div>
      <div class="my-2 py-4 border-t-2 text-left border-vw-bordergray">
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Datum') }}</p>
          <p class="text-vw-green">{{ detail.date }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Klant') }}</p>
          <p class="text-vw-green">{{ detail.requestable.undertaker }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Overledene') }}</p>
          <p class="text-vw-green">{{ detail.requestable.deceased }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Dossiernummer') }}</p>
          <p class="text-vw-green">{{ detail.requestable.file_number }}</p>
        </div>
      </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
  name: 'HistoryDetailModal',
  components: { BaseModal },
  data: () => ({
    url: process.env.VUE_APP_STORAGE_URL + '/storage/products/',
    productData: null, // Store the parsed product data
  }),
  computed: {
    detail(){
      return this.$store.state.modalStore.data;
    }
  },
  mounted() {
    try {
      // Parse the product_data string and store it in productData
      const parsedData = JSON.parse(this.detail.requestable.product_data);
      this.productData = parsedData;

      console.log("Parsed PRODUCT DATA -->", parsedData);
      if (parsedData.selectedInterior) {
        console.log("Interior Name -->", parsedData.selectedInterior.name);
      }
    } catch (error) {
      console.error("Error parsing product_data: ", error);
    }
  }
}
</script>
