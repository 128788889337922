import {
    OPEN_MODAL,
    OPEN_ADD_CUSTOMER_MODAL,
    CLOSE_ADD_CUSTOMER_MODAL,
    CLOSE_MODAL,
    MODAL_CONFIRM_REMOVE_ADDRESS,
    COMPANY_READ,
    LOAD_SETTINGS,
    MODAL_ADD_ADDRESS,
    MODAL_ADD_ADDRESS_REQUEST,
    MODAL_ADD_ADDRESS_REQUEST_COMPLETE,
    MODAL_REFUSE_ADDRESS_REQUEST,
    LOAD_ADDRESSES,
    MODAL_DEREGISTER_DEPOT,
    MODAL_ACCEPT_ADDRESS_REQUEST,
    CHANGE_MODAL_DATA, MODAL_ADD_CLIENT, MODAL_REMOVE_CLIENT_CONFIRM, MODAL_ADD_CLIENT_REQUEST,
    CLIENT_RESET
} from "@/constants";
import axios from "axios";

const state = {
    activeModal: null,
    activeCustomerModal: null,
    data: null,
    customerCreatedData: null
};
const getters = {
    activeModal: state => state.activeModal,
    modalData: state => state.data,
    activeCustomerModal: state => state.activeCustomerModal,
    customerCreatedData: state => state.customerCreatedData
};
const actions = {
    [OPEN_MODAL]({ commit }, payload) {
        commit(OPEN_MODAL, payload);
    },
    [OPEN_ADD_CUSTOMER_MODAL]({ commit }, payload) {
        commit(OPEN_ADD_CUSTOMER_MODAL, payload);
    },
    [CHANGE_MODAL_DATA]({ commit }, payload) {
        commit(CHANGE_MODAL_DATA, payload);
    },
    [CLOSE_MODAL]({ commit }) {
        commit(CLOSE_MODAL);
    },
    [CLOSE_ADD_CUSTOMER_MODAL]({ commit }) {
        commit(CLOSE_ADD_CUSTOMER_MODAL);
    },
    [MODAL_ADD_ADDRESS]({ commit }, payload) {
        let user_id = payload.user_id;
        return axios.post('/addresses/add', {
            user_id: payload.user_id,
            id: payload.id,
        }).then((res) => {
            console.log(res.data);
            // dispatch(LOAD_SETTINGS,{userId:user_id})
            commit(CLOSE_MODAL);
            commit(COMPANY_READ);
            // window.location.reload();
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [MODAL_ADD_CLIENT]({ commit }, payload) {
        console.log(payload);
        return axios.post('/company/client/create', {
            user_id: payload.user_id,
            id: payload.id,
        }).then((res) => {
            commit(CLOSE_MODAL);
            commit(COMPANY_READ);
            dispatch(OPEN_MODAL, { modal: MODAL_DEREGISTER_DEPOT, data: payload.stock });
            // dispatch(LOAD_SETTINGS,{userId:payload.user_id})
            // window.location.reload();
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [MODAL_REMOVE_CLIENT_CONFIRM]({ commit, state, dispatch }, payload) {
        let user_id = parseInt(state.data.user_id);
        return axios.post('/company/client/remove', {
            odoo_id: state.data.odoo_id,
            user_id: state.data.user_id,
        }).then((res) => {
            // console.log(res,payload,user_id);
            commit(CLOSE_MODAL);
            dispatch(COMPANY_READ, user_id);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [MODAL_ADD_CLIENT_REQUEST]({ commit, dispatch }, payload) {
        return axios.post('/company/client/create/request', payload).then((res) => {
            console.log(payload,res);
            let user_id = payload.user_id
            commit(CLOSE_ADD_CUSTOMER_MODAL);
            commit(MODAL_ADD_CLIENT_REQUEST,res.data);
            dispatch(COMPANY_READ, user_id);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [MODAL_ADD_ADDRESS_REQUEST]({ commit, dispatch }, payload) {
        return axios.post('/request/addresses', {
            user_id: payload.company_id,
            name: payload.name,
            address_line: payload.address_line,
            number: payload.number,
            city: payload.city,
            postcode: payload.postcode,
        }).then((res) => {
            commit(CLOSE_MODAL);
            dispatch(OPEN_MODAL, MODAL_ADD_ADDRESS_REQUEST_COMPLETE);
            // dispatch(COMPANY_READ,payload.company_id);
            dispatch(LOAD_SETTINGS, { userId: payload.company_id })
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }

            throw error;
        });
    },
    [MODAL_CONFIRM_REMOVE_ADDRESS]({ commit, dispatch, state }) {
        console.log(state.data);
        let user_id = state.data.user_id;
        axios.post('/addresses/delete', { id: state.data.id })
            .then((res) => {
                commit(CLOSE_MODAL);
                dispatch(COMPANY_READ, user_id);
                dispatch(LOAD_SETTINGS, { userId: user_id })
                // window.location.reload();
            }).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    },

    [MODAL_ACCEPT_ADDRESS_REQUEST]({ commit, dispatch, state }, payload) {
        axios.post('/request/addresses/accept', { data: payload.data, address_id: payload.address_id }).then((res) => {
            dispatch(OPEN_MODAL, MODAL_ACCEPT_ADDRESS_REQUEST);
            dispatch(LOAD_ADDRESSES);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },

    [MODAL_REFUSE_ADDRESS_REQUEST]({ commit, dispatch, state }) {
        axios.post('/request/addresses/refuse', state.data).then((res) => {
            commit(CLOSE_MODAL);
            dispatch(LOAD_ADDRESSES);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [CLIENT_RESET]({ commit, dispatch, state }) {
        commit(CLIENT_RESET);
    }
};
const mutations = {
    [OPEN_MODAL](state, payload) {
        if (typeof payload == "string") {
            state.activeModal = payload;
        } else {
            state.activeModal = payload.modal;
            state.data = payload.data;
        }
    },
    [OPEN_ADD_CUSTOMER_MODAL](state, payload) {
        state.activeCustomerModal = payload.modal;
    },
    [CHANGE_MODAL_DATA](state, payload) {
        state.data = payload.product;
    },
    [CLOSE_MODAL](state) {
        state.activeModal = null;
        state.data = null;
    },
    [CLOSE_ADD_CUSTOMER_MODAL](state) {
        state.activeCustomerModal = null;
    },
    [[MODAL_ADD_CLIENT_REQUEST]](state,payload) {
        state.customerCreatedData = payload?.client;
    },
    [CLIENT_RESET](state) {
        state.customerCreatedData = null;
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};
